import { Location } from "history";
import React, { FC, lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useCursor } from "../context/useCursor";
import Icon from "../utils/Icon";
const privateR = true;
const restricted = true;
const routes = [
  { name: "Panel/Project", path: "/panel/add/projects", privateR },
  { name: "Panel/Skill", path: "/panel/add/skills", privateR },
  { name: "Panel/Testimonial", path: "/panel/add/testimonials", privateR },
  { name: "Login", path: "/login", restricted },
  { name: "Details", path: "/projects/:id" },
  { name: "Home", path: "/" },
  { name: "Panel/Links", path: "/panel/add/contact" },
  { name: "Panel/Links/EditContact", path: "/panel/edit/contact/:id" },
];
const PrivateRoute = ({ component: Comp, ...rest }: { component: FC }) => {
  const { isAuth } = useAuth();
  const Com: FC = (props) =>
    isAuth ? <Comp {...props} /> : <Redirect to="/login" />;
  return <Route {...rest} component={Com} />;
};
const PublicRoute = ({ restricted = false, ...rest }) => {
  const { isAuth } = useAuth();
  const Com: FC = (props) => {
    if (restricted) {
      if (isAuth) return <Redirect to="/panel/add/projects" />;
      else return <rest.component {...props} />;
    } else return <rest.component {...props} />;
  };
  return <Route {...rest} component={Com} />;
};

const renderRoutes = () =>
  routes.map(({ name, privateR, ...rest }) => {
    const Route = privateR ? PrivateRoute : PublicRoute;
    const Component = lazy(() => import(`../pages/${name}`));
    const Loader = () => (
      <div className="loader_wrapper loader_success">
        <Icon loader={true} />
      </div>
    );
    const Com: FC = (props) => (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
    return <Route {...rest} exact component={Com} />;
  });

const Render = (handleMouseLeave: Function, location: Location) => {
  handleMouseLeave();
  return <Switch key={location.key}>{renderRoutes()}</Switch>;
};

export const Routes: FC = () => {
  const { handleMouseLeave } = useCursor();
  return <Route render={(p) => Render(handleMouseLeave, p.location)} />;
};
