import React, { Component, createContext, useContext } from "react";
import firebase, { db } from "../config/Firebase";
import {
  ContactInterface,
  ProjectInterface,
  SkillInterface,
  TestimonialsInterface,
} from "../types/types";
const values = ["projects", "skills", "contact", "testimonials"];

export interface DataContextProps {
  projects: ProjectInterface[];
  skills: SkillInterface[];
  testimonials: TestimonialsInterface[];
  contact: ContactInterface[];
  nameToUrl: (name: string) => string;
  projectsLoaded: boolean;
  skillsLoaded: boolean;
  testimonialsLoaded: boolean;
  contactLoaded: boolean;
  pageLoaded: boolean;
  allLoaded: boolean;
}

const defaults: DataContextProps = {
  projects: [],
  skills: [],
  testimonials: [],
  contact: [],
  nameToUrl: (name: string) => name,
  projectsLoaded: false,
  skillsLoaded: false,
  testimonialsLoaded: false,
  contactLoaded: false,
  pageLoaded: false,
  allLoaded: false,
};

const DataContext = createContext<DataContextProps>(defaults);

export const useData = () => useContext(DataContext);

class DataContextProvider extends Component {
  state = {
    ...defaults,
  };
  async componentDidMount() {
    this.setState({ db });
    const getData = (type: string, typeLoaded: string) => {
      this.setState({ [typeLoaded]: false });
      const onSnapshot = (snapShot: firebase.firestore.QuerySnapshot) => {
        const array = snapShot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        this.setState({ [type]: array, [typeLoaded]: true });
      };
      db.collection(type).onSnapshot(onSnapshot);
    };
    values.forEach((el) => getData(el, el + "Loaded"));
    window.addEventListener("load", () => this.setState({ pageLoaded: true }));
  }
  UNSAFE_componentWillUpdate(
    _prevProps: DataContextProps,
    prevState: DataContextProps
  ) {
    const { pageLoaded, allLoaded } = prevState;
    if (
      !values
        .map((el) => prevState[(el + "Loaded") as keyof DataContextProps])
        .includes(false) &&
      pageLoaded &&
      !allLoaded
    ) {
      const loader = document.querySelector("div.loader_wrapper");
      if (loader) {
        loader.classList.add("drop");
        setTimeout(() => {
          loader.remove();
        }, 1000);
      }
      setTimeout(() => {
        this.setState({ allLoaded: true });
      }, 100);
    }
  }
  nameToUrl = (name: string) => name.split(" ").join("_").toLowerCase();
  render() {
    return (
      <DataContext.Provider
        value={{ ...this.state, nameToUrl: this.nameToUrl }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

export default DataContextProvider;
