import firebase from "../config/Firebase";
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
} from "react";

const AuthContext = createContext({
  user: {},
  isAuth: false,
});

export const useAuth = () => useContext(AuthContext);

const AuthContextProvider: FC = ({ children }) => {
  const [user, setUser] = useState({});
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setIsAuth(true);
      } else {
        setUser({});
        setIsAuth(false);
      }
    });
  }, []);
  const value = { user, isAuth };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
