import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import SwiperCore, {
  EffectCube,
  Thumbs,
  Autoplay,
  Navigation,
  EffectFlip,
  Pagination,
} from "swiper";
import DataContextProvider from "./context/DataContext";
import AuthContextProvider from "./context/AuthContext";
import { Routes } from "./routes/routes";
import CursorProvider from "./context/useCursor";
import "./css/HomeCssImports";
SwiperCore.use([
  EffectCube,
  Thumbs,
  Autoplay,
  Navigation,
  EffectFlip,
  Pagination,
]);
const App = () => {
  return (
    <CursorProvider>
      <AuthContextProvider>
        <DataContextProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </DataContextProvider>
      </AuthContextProvider>
    </CursorProvider>
  );
};

export default App;
