import { gsap } from "gsap";
import React, {
  createContext,
  FC,
  PointerEvent,
  TouchEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import "../components/Mouse/mouse.css";
import { PointerEventType } from "../types/pointer";

const CursorContext = createContext({
  handleMouseEnter: ({ currentTarget }: { currentTarget: HTMLElement }) => {},
  handleMouseLeave: () => {},
});

export const useCursor = () => useContext(CursorContext);

function isTouchEvent(
  e: PointerEventType
): e is TouchEvent<Window & typeof globalThis> {
  return e && "touches" in e;
}

function isPointerEvent(
  e: PointerEventType
): e is PointerEvent<Window & typeof globalThis> {
  return e && "screenX" in e;
}

const CursorProvider: FC = ({ children }) => {
  const animationFrame = useRef<number>(0);
  const { current: cursor } = useRef(document.createElement("div"));
  const { current: cursorF } = useRef(document.createElement("div"));
  const [size] = useState(10);
  const [sizeF] = useState(36);
  const [followSpeed] = useState(0.16);
  const isStuck = useRef(false);
  const ref = useCallback(
    (pointerRef) => {
      console.log(pointerRef);
      if (!pointerRef) return;
      cursor.className = "cursor";
      cursorF.className = "cursor-f";
      cursorF.innerHTML = `<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.4202 42.4202C38.8403 46 33.3594 46 23.5 46C13.6406 46 8.15966 46 4.57983 42.4202C1 38.8403 1 33.3594 1 23.5C1 13.6406 1 8.15966 4.57983 4.57983C8.15966 1 13.6406 1 23.5 1C33.3594 1 38.8403 1 42.4202 4.57983C46 8.15966 46 13.6406 46 23.5C46 33.3594 46 38.8403 42.4202 42.4202Z" stroke="white"/></svg>`;
      let cursorX = 0;
      let cursorY = 0;
      let pageX = 0;
      let pageY = 0;
      pointerRef.innerHTML = "";
      pointerRef.appendChild(cursorF);
      pointerRef.appendChild(cursor);
      cursor.style.setProperty("--size", size + "px");
      cursorF.style.setProperty("--size", sizeF + "px");
      window.addEventListener("pointermove", function (e) {
        pageX = e.clientX;
        pageY = e.clientY;
      });
      const calc = (s: number, p: number) => p - s / 2 + "px";
      const lerp = (s: number, e: number, a: number) => (1 - a) * s + a * e;
      function loop() {
        cursor.style.left = calc(size, pageX);
        cursor.style.top = calc(size, pageY);
        if (!isStuck.current) {
          cursorX = lerp(cursorX, pageX, followSpeed);
          cursorY = lerp(cursorY, pageY, followSpeed);
          cursorF.style.top = calc(sizeF, cursorY);
          cursorF.style.left = calc(sizeF, cursorX);
        }
        animationFrame.current = requestAnimationFrame(loop);
      }
      animationFrame.current = requestAnimationFrame(loop);
      let startY: number;
      let endY: number;
      let clicked = false;

      const pointerdown = (e: Event & PointerEventType): void => {
        gsap.to(cursor, { scale: 4.5, opacity: 0.6 });
        if (!isStuck.current) gsap.to(cursorF, { scale: 0.4 });
        clicked = true;
        if (isTouchEvent(e)) {
          startY = e.touches[0].clientY || e.targetTouches[0].clientY;
        } else if (isPointerEvent(e)) {
          startY = e.clientY;
        }
      };
      function pointerup(e: Event & PointerEventType) {
        gsap.to(cursor, { scale: 1, opacity: 1 });
        gsap.to(cursorF, { scale: 1 });
        if (isPointerEvent(e)) {
          endY = e.clientY || endY;
        }
        if (clicked && startY && Math.abs(startY - endY) >= 40) {
          clicked = false;
          startY = 0;
          endY = 0;
        }
      }
      function touchmove(
        e: Event & TouchEvent<Window & typeof globalThis>
      ): void {
        if (clicked) {
          endY = e.touches[0].clientY || e.targetTouches[0].clientY;
        }
      }
      window.addEventListener("pointerdown", pointerdown, false);
      window.addEventListener("touchstart", pointerdown, false);
      window.addEventListener("touchmove", touchmove, false);
      window.addEventListener("touchend", pointerup, false);
      window.addEventListener("pointerup", pointerup, false);
      const r = () => {
        pointerRef.innerHTML = "";
        window.removeEventListener("pointerdown", pointerdown, false);
        window.removeEventListener("touchstart", pointerdown, false);
        window.removeEventListener("touchmove", touchmove, false);
        window.removeEventListener("touchend", pointerup, false);
        window.removeEventListener("pointerup", pointerup, false);
        cancelAnimationFrame(animationFrame.current);
      };
      if ("ontouchstart" in window) {
        window.ontouchstart = r;
      }
      return r;
    },
    [size, sizeF, cursor, cursorF, followSpeed]
  );
  function handleMouseEnter({ currentTarget }: { currentTarget: HTMLElement }) {
    isStuck.current = true;
    const targetBox = currentTarget.getBoundingClientRect();
    cursorF.classList.add("hovering");
    gsap.to(cursorF, {
      duration: 0.2,
      left: targetBox.left,
      top: targetBox.top,
      width: targetBox.width,
      height: targetBox.height,
      borderRadius: window.getComputedStyle(currentTarget).borderRadius,
    });
  }

  function handleMouseLeave() {
    isStuck.current = false;
    cursorF.classList.remove("hovering");
    gsap.to(cursorF, {
      duration: 0.2,
      width: sizeF,
      height: sizeF,
      borderRadius: "50%",
      backgroundColor: "transparent",
    });
  }

  return (
    <CursorContext.Provider value={{ handleMouseLeave, handleMouseEnter }}>
      {children}
      <div ref={ref} />
    </CursorContext.Provider>
  );
};

export default CursorProvider;
